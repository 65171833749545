import React, { useRef, useEffect, useState } from "react";
import "./App.css";
import Modal from './componentes/Modal';
import verificaciones from "./utils/verificaciones";
import verificarObjeto from "./utils/verificarObjeto.js";
import mostrarErrores from "./utils/mostrarErrores.js";
import { titleCase } from "./utils/validarForms";
//import { toast } from 'react-toastify';

const FormularioRegistro = () => {
	const [estadoModal, cambiarEstadoModal] = useState(false);
	const [mensajeModal, cambiarMensajeModal] = useState("");

	//EXEROM
	const [inputValue, setInputValue] = useState('');
	const [departments, setDepartments] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState("URUGUAY");
	const [selectedDepartment, setSelectedDepartment] = useState("");
	const [selectedLocality, setSelectedLocality] = useState("");
	const formRef = useRef();

	const countries = ['URUGUAY', 'ARGENTINA', 'BRASIL', 'OTRO']
	const handleInputChange = (e) => {


		let value = e.target.value.replace(/\D/g, ''); // Eliminar caracteres no numéricos
		if (value.length > 8) return; // Limitar a 8 caracteres (dd/mm/aaaa)

		if (value.length >= 2 && value.length <= 4) {
			value = value.replace(/(\d{2})(\d{2})/, '$1/$2');
		} else if (value.length >= 4 && value.length <= 8) {
			value = value.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
		}

		setInputValue(value);
	};

	useEffect(() => {
		const fetchDataAndUpdateLocalStorage = async () => {
			try {
				const lastUpdateTime = localStorage.getItem('localidadesLastUpdateTime');
				const currentTime = new Date().getTime();
				const timeDifference = lastUpdateTime ? currentTime - lastUpdateTime : Infinity;
				const localidades = localStorage.getItem('localidades')
				const paises = localStorage.getItem('paises')

				// Si las localidades/países están desactualizados o no existen..
				if (!lastUpdateTime || timeDifference > 2 * 24 * 60 * 60 * 1000 || !localidades || !paises) {

					const response = await fetch(process.env.REACT_APP_ADDRESS + '/localidades/');
					const data = await response.json();
					console.log(data)
					// Agrupo localidades por departamento
					const localidadesPorDepartamento = {};
					data.forEach((localidad) => {
						const departamentoId = localidad.departamento.id;
						const departamentoNombre = localidad.departamento.nombre;
						const nombreLocalidad = localidad.nombre;
						const localidadId = localidad.id;

						if (!localidadesPorDepartamento[departamentoNombre]) {
							localidadesPorDepartamento[departamentoNombre] = {
								id: departamentoId,
								localidades: []
							};
						}

						localidadesPorDepartamento[departamentoNombre].localidades.push({
							id: localidadId,
							nombre: nombreLocalidad
						});
					});

					const paises = {
						'ARGENTINA': localidadesPorDepartamento['ARGENTINA'].localidades[0].id,
						'BRASIL': localidadesPorDepartamento['BRASIL'].localidades[0].id,
						'OTRO PAIS': localidadesPorDepartamento['OTRO PAIS'].localidades[0].id
					}

					// Saco a los países localidad para que no aparezcan en el select
					delete localidadesPorDepartamento['ARGENTINA'];
					delete localidadesPorDepartamento['BRASIL'];
					delete localidadesPorDepartamento['OTRO'];
					delete localidadesPorDepartamento['SIN DEFINIR'];
					delete localidadesPorDepartamento['OTRO PAIS'];

					setDepartments(localidadesPorDepartamento);
					localStorage.setItem('localidades', JSON.stringify(localidadesPorDepartamento));
					localStorage.setItem('paises', JSON.stringify(paises));

					// Actualizo la marca de tiempo
					localStorage.setItem('localidadesLastUpdateTime', currentTime.toString());
				} else {
					// Obtener datos del Local Storage si no ha pasado el tiempo establecido
					const data = JSON.parse(localStorage.getItem('localidades'));
					setDepartments(data || {});
				}
			} catch (error) {
				console.error("Error al obtener los departamentos y localidades", error);
			}
		};

		fetchDataAndUpdateLocalStorage();
	}, [selectedCountry]);

	const handleCountryChange = (event) => {
		setSelectedCountry(event.target.value);
		setSelectedDepartment(""); // Reinicia el departamento seleccionado cuando cambia el país.
	};
	const handleDepartmentChange = (event) => {
		setSelectedDepartment(event.target.value);
		console.log(event.target.value)
		setSelectedLocality(""); // Reinicia la localidad seleccionada cuando cambia el departamento.
	};
	function convertirFormatoFecha(fecha) {
		const [dia, mes, año] = fecha.split('/');
		const fechaNueva = new Date(año, mes - 1, dia);
		return fechaNueva.toISOString().split('T')[0];
	}
	const fetchForm = async () => {
		const formData = new FormData(formRef.current);
		const data = Object.fromEntries(formData.entries());


		if (data.pais !== 'URUGUAY') {
			data.localidad = data.pais.toUpperCase()
		}
		const verificacionesObj = verificaciones(data)
		if (data.departamento)
			delete data.departamento
		if (verificarObjeto(verificacionesObj)) {
			//const searchUrl = import.meta.env.VITE_ADDRESS + `create-cliente-auth/`; USO EL ENDPOINT SIN EL AUTH
			const searchUrl = process.env.REACT_APP_ADDRESS + `/enviar/`;
			const headers = {
				//Authorization: authorizationToken, COMO NO USO EL 'AUTH' NO LO NECESITO
				'Content-Type': 'application/json'
			};
			data.fecha_nacimiento = convertirFormatoFecha(data.fecha_nacimiento)
			data.nombre = titleCase(data.nombre)
			data.apellido = titleCase(data.apellido)
			data.direccion = titleCase(data.direccion)
			//const sucursal = localStorage.getItem("sucursal"); NO VA PORQUE SE CREA DESDE LA LANDING
			//if (sucursal) data.sucursal = sucursal; NO VA PORQUE SE CREA DESDE LA LANDING
			console.log(data)
			const response = await fetch(searchUrl, {
				method: 'POST',
				headers,
				body: JSON.stringify(data)
			});

			if (response.status === 200 || response.status === 201) {
				cambiarEstadoModal(true);
				cambiarMensajeModal("¡Te has registrado como cliente PRO!");
				cleanForm()
			} else {
				cambiarEstadoModal(true);
				cambiarMensajeModal("Error al enviar los datos! Intenta de nuevo más tarde por favor");
			}
			//console.log(data)

		} else {
			mostrarErrores(verificacionesObj)
		}
	}
	const handleSubmit = async (event) => {
		event.preventDefault();
		fetchForm()
	};
	const cleanForm = () => {
		formRef.current
			?.querySelectorAll("input, select")
			.forEach((element) => {
				element.value = "";
				if (element.id === "pais") {
					setSelectedCountry("URUGUAY");
				}
				if (element.id === "fecha_nacimiento") {
					setInputValue("");
				}
				if (element.id === "tipo_doc") {
					element.value = "CI";
				}
			});
	};
	//EXEROM CLOSE

	// const [formValues, setFormValues] = useState({
	// 	tipo_doc: "",
	// 	nro_doc: "",
	// 	nombre: "",
	// 	apellido: "",
	// 	genero: "",
	// 	direccion: "",
	// 	localidad: "",
	// 	telefono: "",
	// 	celular: "",
	// 	mail: "",
	// 	fecha_nac: "",
	// });

	// const limpiarFormulario = () => {
	// 	setFormValues({
	// 		tipo_doc: "",
	// 		nro_doc: "",
	// 		nombre: "",
	// 		apellido: "",
	// 		genero: "",
	// 		direccion: "",
	// 		localidad: "",
	// 		telefono: "",
	// 		celular: "",
	// 		mail: "",
	// 		fecha_nac: "",
	// 	});
	// };

	// const handleSubmit = async (event) => {
	// 	event.preventDefault();
	// 	//const url = "http://localhost:5000/api/clientes/v1/add";
	// 	//const url = "http://192.168.100.37:5000/api/clientes/v1/add";
	// 	const url = "https://pro.eldorado.com.uy/api/clientes/v1/add";
	// 	const auth = "admin:secret";
	// 	//const credentials = Buffer.from(auth).toString("base64");
	// 	const credentials = btoa(auth)
	// 	fetch(url, {
	// 		method: "POST",
	// 		body: JSON.stringify(formValues),
	// 		headers: {
	// 			"Content-Type": "application/json",
	// 			"Authorization": "Basic " + credentials
	// 		}
	// 	})
	// 		.then(response => {
	// 			if (response.ok) {
	// 				console.log("Llamada exitosa");
	// 				limpiarFormulario(); // Limpiar los valores del formulario
	// 			} else {
	// 				console.log("Llamada fallida");
	// 			}
	// 			return response.json()
	// 		})
	// 		.then(data => {
	// 			console.log(JSON.stringify(data));
	// 			cambiarEstadoModal(true);
	// 			cambiarMensajeModal(data.message);
	// 		})
	// 		.catch(error => {
	// 			console.error("Error al guardar los datos:", error);
	// 			cambiarEstadoModal(true);
	// 			cambiarMensajeModal("Error al enviar los datos");
	// 		});
	// };

	// const handleInputChange = (event) => {
	// 	const { name, value } = event.target;
	// 	setFormValues({
	// 		...formValues,
	// 		[name]: value,
	// 	});
	// };

	return (
		<div className="body">
			<section className="superior">
				<img src="Imagenes/fondo_superior.jpg" alt="foto" />
				<nav className="midel">
				</nav>
			</section>

			<div className="layout">
				<section className="titulo">
					<img src="Imagenes/activa_tu_cuenta_pro_aqui.png" alt="foto" />
				</section>

				{/* FORM EXEROM */}
				<form ref={formRef} onSubmit={handleSubmit} className="formulario" >

					<div className="campo-formulario">
						<label htmlFor="tipo_doc" className="form-label">Tipo de documento *</label>
						<select
							className="form-select"
							id="tipo_doc"
							name="tipo_doc"
							defaultValue={"CI"}
						>
							<option value="">Seleccionar</option>
							<option value="CI">CI</option>
							<option value="DNI">DNI</option>
							<option value="RUT">RUT</option>
							<option value="CUIT">CUIT</option>
							<option value="PASAPORTE">PASAPORTE</option>
							<option value="OTRO">OTRO</option>
						</select>
					</div>
					<div className="campo-formulario">
						<label htmlFor="nro_doc" className="form-label">Número de documento *</label>
						<input
							type="number"
							className="form-control"
							id="nro_doc"
							name="nro_doc"
							maxLength="15"
						/>
					</div>
					<div className="campo-formulario">
						<label htmlFor="nombre" className="form-label">Nombre *</label>
						<input
							type="text"
							className="form-control"
							id="nombre"
							name="nombre"
							maxLength="50"
						/>
					</div>
					<div className="campo-formulario">
						<label htmlFor="apellido" className="form-label">Apellido *</label>
						<input
							type="text"
							className="form-control"
							id="apellido"
							name="apellido"
							maxLength="30"
						/>
					</div>
					<div className="campo-formulario">
						<label htmlFor="fecha_nacimiento" className="form-label">Fecha de nacimiento *<small className="text-muted"> Ejemplo: 28/11/2000</small></label>
						<input
							type="text"
							className="form-control"
							id="fecha_nacimiento"
							placeholder="dd/mm/aaaa"
							name="fecha_nacimiento"
							value={inputValue}
							onChange={(e) => { handleInputChange(e) }}
						/>
					</div>
					<div className="campo-formulario">
						<label htmlFor="genero" className="form-label">Género *</label>
						<select
							className="form-select"
							id="genero"
							name="genero"
						>
							<option value="">Seleccionar</option>
							<option value="F">Femenino</option>
							<option value="M">Masculino</option>
							<option value="OTRO">Otro</option>
						</select>
					</div>
					<div className="campo-formulario">
						<label htmlFor="celular" className="form-label">Número de celular</label>
						<input
							type="number"
							className="form-control"
							id="celular"
							name="celular"
							maxLength="15"
						/>
					</div>
					<div className="campo-formulario">
						<label htmlFor="telefono" className="form-label">Número de teléfono</label>
						<input
							type="number"
							className="form-control"
							id="telefono"
							name="telefono"
							maxLength="15"
						/>
					</div>
					<div className="campo-formulario">
						<label htmlFor="mail" className="form-label">e-mail *</label>
						<input
							type="text"
							className="form-control"
							id="mail"
							name="mail"
						/>
					</div>
					<div className="campo-formulario">
						<label htmlFor="direccion" className="form-label">Dirección *</label>
						<input
							type="text"
							className="form-control"
							id="direccion"
							name="direccion"
							maxLength="50"
						/>
					</div>
					<div className="campo-formulario">
						<label htmlFor="pais" className="form-label">País *</label>
						<select
							className="form-select"
							id="pais"
							name="pais"
							onChange={(e) => { handleCountryChange(e) }}
							value={selectedCountry}
						>
							<option value="" selected>Seleccionar</option>
							{countries.map((countryName, index) => (
								<option key={index} value={countryName}>
									{countryName}
								</option>
							))}
						</select>
					</div>
					<div className="campo-formulario">
						{selectedCountry === "URUGUAY" && (
							<>
								<label htmlFor="departamento" className="form-label">Departamento *</label>
								<select
									className="form-select"
									id="departamento"
									name="departamento"
									onChange={(e) => { handleDepartmentChange(e)}}
									value={selectedDepartment}
								>
									<option value="">Seleccionar</option>
									{Object.keys(departments).sort().map((department, index) => (
										<option key={index} value={department}>
											{department}
										</option>
									))}
								</select>
							</>
						)}
					</div>
					<div className="campo-formulario">
						{selectedDepartment !== "" && (
							<>
								<label htmlFor="localidad" className="form-label">Localidad *</label>
								<select
									className="form-select"
									id="localidad"
									name="localidad"
									onChange={(event) => { setSelectedLocality(event.target.value) }}
									value={selectedLocality}
								>
									<option value="">Seleccionar</option>
									{departments[selectedDepartment].localidades.sort().map((locality) => (
                                            <option key={locality.id} value={locality.id}>
                                                {locality.nombre}
                                            </option>
                                        ))}
								</select>
							</>
						)}
					</div>

					<div className="campo-formulario">
						<br />
						<button type="submit" className="boton-enviar" style={{ width: "100%", marginTop: '7px' }}>
							ENVIAR
						</button>
					</div>
				</form>
			</div>

			<section className="footer">
				<article className="inf-footer">
					<div className="text-footer">
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
								className="bi bi-person" viewBox="0 0 16 16">
								<path
									d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
							</svg>
							<span> Atención telefónica </span>
						</div>
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
								className="bi bi-phone" viewBox="0 0 16 16">
								<path
									d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
								<path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
							</svg>
							<span> (+598) 9980 1990</span>
						</div>
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
								className="bi bi-telephone-fill" viewBox="0 0 16 16">
								<path fill-rule="evenodd"
									d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
							</svg>
							<span> 0800 1990</span>
						</div>
					</div>

					<div className="text-footer">
						<div>
							<a href="https://drive.google.com/file/d/1URDymbopX-JawD-YBJVBEk_l1whTJQZb/view"
								className="condiciones"><span>Terminos y condiciones</span></a>
						</div>
						<div>
							<a href="https://es-la.facebook.com/eldoradouy/">
								<img src="Imagenes/Facebook.JPG" alt="facebook" className="logoLink" />
							</a>
							<a href="https://www.instagram.com/eldoradouy/?hl=es">
								<img src="Imagenes/Instagram.JPG" alt="instagram" className="logoLink" />
							</a>
							<a href="https://www.eldorado.com.uy/">
								<img src="Imagenes/Web.JPG" alt="web" className="logoLink" />
							</a>
						</div>
					</div>

					<img src="Imagenes/logo.png" alt="logo" className="logoFooter" />
				</article>

			</section>


			<Modal
				estado={estadoModal}
				cambiarEstado={cambiarEstadoModal}
				titulo="Aviso"
				mostrarTitulo={false}
				oscurecerFondo={true}
				mensaje={mensajeModal}
			/>

		</div>
	);
};

export default FormularioRegistro;
